import type { TooltipPlacement } from 'antd/es/tooltip'
import dynamic from 'next/dynamic'
import type { FC } from 'react'

import { useAppSelector } from '@redux/hooks'
import { selectIsPageInteraction } from '@reduxCommonApp/commonApp.selectors'

import { RegionalMenuContainer } from './styles'

const MenuContainer = dynamic(() => import('./components/MenuContainer'))
const RegionalMenuPopover = dynamic(() => import('./components/RegionalMenuPopover'))

type Props = {
  placement?: TooltipPlacement
  variant?: 'classic' | 'default'
}

export const RegionalMenu: FC<Props> = ({ placement = 'bottomRight', variant = 'default' }) => {
  const isPageInteraction = useAppSelector(selectIsPageInteraction)

  return (
    <RegionalMenuContainer>
      <MenuContainer variant={variant} />
      {isPageInteraction && <RegionalMenuPopover placement={placement} />}
    </RegionalMenuContainer>
  )
}
